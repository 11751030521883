import { Vue } from "vue-property-decorator";
import { createModule, mutation } from "vuex-class-component";

const VuexModule = createModule({
  namespaced: "dialog",
  strict: true,
});

export class DialogStore extends VuexModule {
  status = false;
  text = "";
  callback: (() => unknown) | null = null;
  snackbar = false;
  snackbarText = "";
  snackbarColor = "success";
  @mutation
  ask({ text, callback }: { text: string; callback: () => unknown }) {
    this.status = true;
    this.text = text;
    this.callback = callback;
  }

  @mutation
  popup({ text = "", color = "success" }) {
    if (this.snackbar) {
      this.snackbar = false;
      Vue.nextTick(() => {
        this.snackbar = true;
        this.snackbarText = text;
      });
    } else {
      this.snackbar = true;
      this.snackbarText = text;
      this.snackbarColor = color;
    }
  }

  @mutation
  setSnackbar(val: boolean) {
    this.snackbar = val;
  }

  @mutation
  close() {
    this.status = false;
  }
}
