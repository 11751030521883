import { UserTransaction } from "@/models/UserTransaction";
import { api } from "@/services/api";
import { action, createModule, mutation } from "vuex-class-component";

const VuexModule = createModule({
  namespaced: "transactions",
  strict: false,
});

export class TransactionsStore extends VuexModule {
  public transactions: UserTransaction[] = [];
  public loading = false;
  public loaded = false;

  @action
  async LoadTransactions() {
    this.loading = true;
    this.transactions = await api.get<UserTransaction[]>("events/user-transactions");
    this.loading = false
    this.loaded = true;
  }

  // @mutation
  // add(transaction: UserTransaction) {
  //   this.transactions.push(transaction);
  //   localStorage.setItem("transactions", JSON.stringify(this.transactions));
  // }

  // @mutation
  // updateTransaction(transaction: UserTransaction) {
  //   const idx = this.transactions.findIndex((x) => x.id == transaction.id);
  //   if (idx !== -1) {
  //     Vue.set(this.transactions, idx, transaction);
  //     localStorage.setItem("transactions", JSON.stringify(this.transactions));
  //   } else {
  //     this.transactions.push(transaction);
  //   }
  // }

  // @mutation
  // removeTransaction(id: string) {
  //   const idx = this.transactions.findIndex((x) => x.id == id);
  //   if (idx !== -1) {
  //     this.transactions.splice(idx, 1);
  //     localStorage.setItem("transactions", JSON.stringify(this.transactions));
  //   }
  // }
}
