import { Category } from "@/models/Category";
import { api } from "@/services/api";
import { action, createModule, mutation } from "vuex-class-component";

const VuexModule = createModule({
  namespaced: "categories",
  strict: true,
});

export class CategoriesStore extends VuexModule {
  categories: Category[] = [];
  loaded = false;
  
  @mutation
  setCategories(categories: Category[]) {
    this.categories = categories;
  }

  @mutation
  setLoaded() {
    this.loaded = true;
  }

  @action
  public async LoadCategories() {
    const categories = await api.get<Category[]>("categories");
    this.setCategories(categories);
    this.setLoaded();
  }
}
