import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

// router.beforeEach(async (to, from, next) => {
//   const msal = useMsal();
//   await msal.waitForMsal.promise;
//   next();
// });

export default router;
