import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store, storeProxy } from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
import "./index.css";
import { api } from "./services/api";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import PortalVue from 'portal-vue'

Vue.config.productionTip = false;

Vue.component("req", () => import("./components/req.vue"));
Vue.component("custom-field-text", () => import("./components/custom-field-text.vue"));
Vue.component("custom-field-select", () => import("./components/custom-field-select.vue"));
Vue.component("custom-field-multiselect", () => import("./components/custom-field-multiselect.vue"));
Vue.component("custom-field-number", () => import("./components/custom-field-number.vue"));
Vue.component("custom-field-file", () => import("./components/custom-field-file.vue"));
Vue.component("custom-field-switch", () => import("./components/custom-field-switch.vue"));
Vue.component("custom-field-date", () => import("./components/custom-field-date.vue"));
Vue.component("custom-field-phone", () => import("./components/custom-field-phone.vue"));
Vue.component("custom-field-email", () => import("./components/custom-field-email.vue"));
Vue.use(PortalVue)

export let appInsights: ApplicationInsights = null;

if (process.env.NODE_ENV === 'production') {
 appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=7cc08649-c613-4fe1-999b-3bfeb647d2bf;IngestionEndpoint=https://francecentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://francecentral.livediagnostics.monitor.azure.com/;ApplicationId=280572d4-2486-4640-87ec-7a8218fcafdd',
    enableAutoRouteTracking: true,
    enableDebugExceptions: true,
  }
});
appInsights.loadAppInsights();
appInsights.clearAuthenticatedUserContext()
appInsights.trackPageView();
console.log("appInsights", appInsights);
}
// api.onUnauthorized = () => {
//   storeProxy.user.setLogout();
//   router.replace('/login')
// };

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
