import { msalInstance } from "@/authConfig";
import { accountArraysAreEqual } from "@/helpers/accountArraysAreEqual";
import { AuthenticationResult, EventMessage, EventMessageUtils, EventType, InteractionStatus } from "@azure/msal-browser";
import { reactive } from "vue";

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
class Deferred {
    promise: Promise<unknown>;
    reject: (reason?: any) => void;
    resolve: (value?: unknown) => void;
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.reject = reject;
            this.resolve = resolve;
        });
    }
}

const inProgress = InteractionStatus.Startup;

export const msalState = reactive({
    instance: msalInstance,
    inProgress: inProgress,
    loaded: false,
    accounts: accounts,
    waitForMsal: new Deferred()
});

// app.config.globalProperties.$msal = state;

msalInstance.addEventCallback((message: EventMessage) => {
    switch (message.eventType) {
        case EventType.ACCOUNT_ADDED:
        case EventType.ACCOUNT_REMOVED:
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_END:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
            // eslint-disable-next-line no-case-declarations
            const currentAccounts = msalInstance.getAllAccounts();
            if (!accountArraysAreEqual(currentAccounts, msalState.accounts)) {
                msalState.accounts = currentAccounts;
            }
            break;
    }

    const status = EventMessageUtils.getInteractionStatusFromEvent(message, msalState.inProgress);
    if (status !== null) {
        msalState.inProgress = status;
    }
});