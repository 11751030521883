import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

const prod = process.env.NODE_ENV === 'production';

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
        editProfile: "B2C_1A_PROFILEEDIT",
        resetPassword: "B2C_1A_PASSWORDRESET",
    },
    authorities: {
        signUpSignIn: {
            authority: "https://dcotech.b2clogin.com/dcotech.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
        },
        editProfile: {
            authority: "https://dcotech.b2clogin.com/dcotech.onmicrosoft.com/B2C_1A_PROFILEEDIT"
        },
        resetPassword: {
            authority: "https://dcotech.b2clogin.com/dcotech.onmicrosoft.com/B2C_1A_PASSWORDRESET"
        }
    },
    authorityDomain: "dcotech.b2clogin.com"
}

// "https://login.microsoftonline.com/tfp/dcotech.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/oauth2/v2.0/authorize"
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        instance: "https://dcotech.b2clogin.com",
        clientId: '185c2822-aa7e-4030-8191-fb778466a8bf',
        redirectUri: prod ? 'https://event.copte.fr/' : 'http://localhost:8080', // Must be registered as a SPA redirectURI on your app registration
        postLogoutRedirectUri: prod ? 'https://event.copte.fr/' : 'http://localhost:8080', // Must be registered as a SPA redirectURI on your app registration
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
    },
    cache: {
        cacheLocation: 'localStorage'
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    // case LogLevel.Info:
                    //     console.info(message);
                    //     return;
                    // case LogLevel.Verbose:
                    //     console.debug(message);
                    //     return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose
        }
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);
const apiConfig = {
    b2cScopes: ["https://dcotech.onmicrosoft.com/event-api/user.event.read"],
    // webApi: "https://mydomain.azurewebsites.net/tasks"
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [...apiConfig.b2cScopes] as any,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const tokenRequest = {
    scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
    forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
    authority: "https://dcotech.b2clogin.com/dcotech.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN"
};