import { EventUserRole } from "@/models/EventUserRole";
import { api } from "@/services/api";
import { action, createModule } from "vuex-class-component";

const VuexModule = createModule({
  namespaced: "eventuserroles",
  strict: false,
});

export class EventUserRolesStore extends VuexModule {
  public eventRoles: EventUserRole[] = [];
  public loading = false;
  public loaded = false;

  @action
  async LoadEventUserRoles() {
    this.loading = true;
    this.eventRoles = await api.get<EventUserRole[]>("EventUserRole");
    this.loading = false
    this.loaded = true;
  }
}