import { Event } from "@/models/event";
import { api } from "@/services/api";
import Vue from "vue";
import { action, createModule, mutation } from "vuex-class-component";
import { storeProxy } from ".";

const VuexModule = createModule({
  namespaced: "events",
  strict: false,
});

export class EventsStore extends VuexModule {
  public events: Event[] = [];
  public loading = false;
  public loaded = false;
  public page = 1;
  public noMoreEvents = false;
  public differentPage = false;
  public showErrors: Event | null = null;

  @mutation
  setEvents(events: Event[]) {
    this.events = events;
  }

  @mutation
  incrementPage() {
    this.page++;
    this.differentPage = true;
  }

  @action
  public async LoadEvents() {
    this.loading = true;
    // if (storeProxy.user.isAdmin) this.events = await api.get<Event[]>("events/AllEvents");
    const oldEvents = this.events
    const newEvents = await api.get<Event[]>("events", { page: this.page });
    // if (newEvents.length === 0) this.noMoreEvents = true;
    // if we already have the new events we loaded them all
    if (this.differentPage && (newEvents.length === 0 || newEvents.every((e) => oldEvents.some((o) => o.id == e.id)))) {
      this.noMoreEvents = true;
    }
    this.events = [...oldEvents, ...newEvents];
    // remove duplicates
    this.events = this.events.filter((v, i, a) => a.findIndex((t) => t.slug === v.slug) === i);
    this.loading = false;
    this.loaded = true;
    this.differentPage = false;
  }
  @action
  public async ReLoadEvents() {
    this.loading = true;
    const ev = await api.get<Event[]>("events", { page: this.page });
    this.events = ev;
    this.loading = false;
    this.loaded = true;
    this.differentPage = false;
  }

  @action
  public async resetEvents() {
    this.events = [];
    this.page = 1;
    this.noMoreEvents = false;
    this.differentPage = false;
    
  }


  // @action
  // public async LoadUserEvents() {
  //   // this.loading = true;
  //   this.events = await api.get<Event[]>("events/user-events");
  //   // this.loading = false;
  //   // this.loaded = true;
  // }

  // @action
  // public async test() {
  //   this.events = await api.get("events/test");
  // }

  @action
  public async LoadEvent(slug: string) {
    this.loading = true;
    const event = await api.get<Event>("events/" + slug);
    if (event) {
      const index = this.events.findIndex((e) => e.slug === event.slug);

      if (index === -1) {
        this.events.push(event);
      } else {
        Vue.set(this.events, index, event);
      }
    }
    this.loading = false;
    this.loaded = true;
  }
}
