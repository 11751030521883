import Vue from "vue";
import Vuex from "vuex";
import { createProxy, extractVuexModule } from "vuex-class-component";
import { UserStore } from "./user-store";
import { DialogStore } from "./dialog-store";
import { EventsStore } from "./events-store";
import { TransactionsStore } from "./transactions-store";
import { EventUserRolesStore } from "./event-user-roles-store";
import { CategoriesStore } from "./categories";

Vue.use(Vuex);

// Add more store modules here
export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(UserStore),
    ...extractVuexModule(DialogStore),
    ...extractVuexModule(EventsStore),
    ...extractVuexModule(TransactionsStore),
    ...extractVuexModule(EventUserRolesStore),
    ...extractVuexModule(CategoriesStore),
  },
});

// Add more store modules here
export const storeProxy = {
  user: createProxy(store, UserStore),
  dialog: createProxy(store, DialogStore),
  events: createProxy(store, EventsStore),
  transactions: createProxy(store, TransactionsStore),
  eventUserRoles: createProxy(store, EventUserRolesStore),
  categories: createProxy(store, CategoriesStore),
};

Vue.prototype.store = storeProxy;
