import { RouteConfig } from "vue-router";

// To lazy load a page
// vue-cli automaticly add prefetch to the routes so that the transitions between pages is almost seamless
function lazyLoad(view: string) {
  return () => import(/* webpackChunkName: "route-[request]" */ `@/views/${view}`);
}

const routes: Array<RouteConfig> = [
  // {
  //   path: "/login",
  //   name: "login",
  //   component: lazyLoad("Login"),
  // },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: lazyLoad("Register"),
  // },
  {
    path: "/",
    name: "home",
    component: lazyLoad("Home/Home"),
  },
  {
    path: "/success",
    name: "success",
    component: lazyLoad("Success"),
  },
  {
    path: "/cancel",
    name: "cancel",
    component: lazyLoad("Cancel"),
  },
  {
    path: "/transaction/:id",
    name: "transaction",
    component: lazyLoad("Transaction/Transaction"),
  },
  {
    path: "/mes-evenements",
    name: "mes-transaction",
    component: lazyLoad("MesTransactions/MesTransactions"),
  },
  {
    path: "/admin",
    name: "admin",
    component: lazyLoad("Admin/Admin"),
  },
  {
    path: "/admin/event/new",
    name: "admin-event-new",
    component: lazyLoad("Admin/Event/New"),
  },
  {
    path: "/admin/event/:slug",
    name: "admin-event",
    component: lazyLoad("Admin/Event/Event"),
  },
  {
    path: "/admin/event/edit/:slug",
    name: "admin-event-edit",
    component: lazyLoad("Admin/Event/Edit"),
  },
  {
    path: "/admin/event/:slug/survey/:id",
    name: "admin-survey",
    component: lazyLoad("Admin/Event/Surveys/Id"),
  },
  {
    path: "/:slug",
    name: "event",
    component: lazyLoad("Event/Event"),
  },
  {
    path: "/:slug/survey/:id",
    name: "survey",
    component: lazyLoad("Event/Survey/Survey"),
  }
];

export default routes;
