import { msalState } from "@/store/msalStore";
import { InteractionStatus } from "@azure/msal-browser";
import { computed, getCurrentInstance, ref, toRef } from "vue";
import { tokenRequest, b2cPolicies, msalInstance } from "@/authConfig";
import { storeProxy } from "@/store";

export function useMsal() {
    const internalInstance = getCurrentInstance();
    if (!internalInstance) {
        throw "useMsal() cannot be called outside the setup() function of a component";
    }

    if (msalState.inProgress === InteractionStatus.Startup) {
        // console.log("handleRedirectPromise");
        msalInstance.handleRedirectPromise().then(async (res) => {
            if (res && res.accessToken) {
                console.log("RESSS : ", res);
                storeProxy.user.setLogin(res.accessToken);
                msalState.waitForMsal.resolve();
                msalState.loaded = true;
                return;
            }
            else if (res && res.authority.includes("profileedit")) {
                // console.log("RESSS2 : ", res);
                const oldconfig = localStorage.getItem("dark");
                localStorage.clear();
                if (oldconfig)
                    localStorage.setItem("dark", oldconfig);
                login();
                return;
            }
            // console.log("handleRedirectPromise", msalState.accounts.length, msalState.inProgress);
            await acquireTokenSilent();
            msalState.loaded = true;
            msalState.waitForMsal.resolve();
            return;
        }).catch(async (e) => {
            console.log(e);
            if (e.errorMessage.search("AADB2C90118") !== -1) {
                resetPassword();
            }
            if (e.errorMessage.includes("cancelled")) {
                await acquireTokenSilent();
                msalState.loaded = true;
                msalState.waitForMsal.resolve();
            }
            msalState.waitForMsal.resolve();
            msalState.loaded = true;
        })
    }

    const acquireTokenSilent = async () => {
        // console.log("alo : ", msalState.accounts.length, msalState.inProgress);
        if (msalState.accounts.length > 0 && msalState.inProgress === InteractionStatus.None) {
            // console.log("acquireTokenSilent");
            msalState.loaded = false;
            try {
                const response = await msalInstance.acquireTokenSilent(tokenRequest);
                storeProxy.user.setLogin(response.accessToken);
                msalState.loaded = true;
                msalState.waitForMsal.resolve();
                return;
            }
            catch (error) {
                msalState.loaded = true;
                msalState.waitForMsal.resolve();
                console.log(error);
                return;
            }
        }
    }

    // if (!storeProxy.user.token)
    //     acquireTokenSilent();

    const login = async () => {
        if (msalState.inProgress === InteractionStatus.None) {
            try {
                console.log("Login : ", msalState.inProgress);
                if (msalState.accounts.length > 0) {
                    console.log("LoginREDIRECTTOKEN : ", msalState.accounts.length);
                    msalInstance.acquireTokenRedirect(tokenRequest)
                } else {
                    await msalState.instance.loginRedirect({ extraQueryParameters: { ui_locales: 'fr' } } as any)
                }
            } catch (error) {
                console.log(error);
                msalState.waitForMsal.resolve();
                msalState.loaded = true;
            }
        }
    };

    const resetPassword = async () => {
        const editProfileRequest = b2cPolicies.authorities.resetPassword;
        await msalState.instance.loginRedirect(editProfileRequest as any);
    }

    const editProfile = async () => {
        const editProfileRequest = b2cPolicies.authorities.editProfile;
        await msalState.instance.loginRedirect(editProfileRequest as any);
    }

    const logout = () => {
        if (msalState.accounts.length > 0) {
            msalState.instance.logoutRedirect();
        }
    }

    const isAuthenticated = computed(() => {
        return msalState.accounts.length > 0 && storeProxy.user.token != null;
    });


    return {
        waitForMsal: msalState.waitForMsal,
        isAuthenticated,
        login,
        loaded: toRef(msalState, 'loaded'),
        logout, editProfile,
        resetPassword
    }
}