import { appInsights } from "@/main";
import { api } from "@/services/api";
import jwtDecode from "jwt-decode";
import LogRocket from "logrocket";
import { action, createModule, mutation } from "vuex-class-component";

const VuexModule = createModule({
  namespaced: "user",
  strict: true,
});

// const TOKEN_LOCAL_STORAGE = "user_token";

// const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);

// const decoded = token
//   ? jwtDecode<{ nameid: string; email: string; unique_name: string }>(token)
//   : null;

// if (token != null) {
//   api.setToken(`Bearer ${token}`);
// }
export class UserStore extends VuexModule {
  public isLogged = false;
  public token: string | null = null;
  public id: string | null = null;
  public name: string | null = null;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public email: string | null = null;
  public mobile: string | null = null;
  public expiresOn: Date | null = null;
  public dateOfBirth: Date | null = null;
  public gender: string | null = null;
  public isAdmin = false;

  @mutation
  setLogin(token: string) {
    // console.log("setLogin", token);

    const decoded = jwtDecode<{ family_name: string; given_name: string; mobile: string; extension_dateOfBirth: number; extension_gender: string; sub: string; email: string; emails: string[]; extension_GlobalAdmin: boolean; exp: number }>(token);
    // console.log(decoded);

    this.id = decoded.sub;
    this.email = decoded.email;
    this.firstName = decoded.given_name;
    this.lastName = decoded.family_name;
    this.name = decoded.given_name + " " + decoded.family_name;
    this.token = token;
    this.isLogged = true;
    this.isAdmin = decoded.extension_GlobalAdmin;
    this.expiresOn = new Date(decoded.exp * 1000);
    this.dateOfBirth = new Date(decoded.extension_dateOfBirth * 1000);
    this.gender = decoded.extension_gender;
    this.mobile = decoded.mobile;
    // console.log(`%cthis.expiresOn : ${this.expiresOn}`, "color: red");
    api.setToken(`Bearer ${token}`);
    
    if (process.env.NODE_ENV === 'production') {
      console.log("LogRocket.identify", this.id);
      LogRocket.identify(this.id, {
        name: this.name,
        email: this.email,
      }); 
      appInsights?.setAuthenticatedUserContext(this.email, this.name.replace(/\s/g, ''), true);
    }
  }

  @mutation
  setLogout() {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.name = null;
    this.email = null;
    this.token = null;
    this.isLogged = false;
    this.isAdmin = false;
    api.setToken(null);
    // localStorage.removeItem(TOKEN_LOCAL_STORAGE);
  }

  // @action
  // public async Login(credentials: { email: string; password: string }) {
  //   if (!this.isLogged) {
  //     const res = await api.post<string>("auth/login", credentials).catch((error) => {
  //       throw { status: error.response.status, message: error.response.data };
  //     });
  //     this.setLogin(res);
  //     return res;
  //   }
  // }

  // @action
  // async register(credentials: { email: string; name: string; password: string }) {
  //   const res = await api.post("auth/register", credentials).catch((error) => {
  //     throw { status: error.response.status, message: error.response.data };
  //   });
  //   return res;
  // }

  @action
  async Logout() {
    this.setLogout();
  }
}
