import { extend, localize } from "vee-validate";
import { required, email, max, min, min_value, max_value, numeric } from "vee-validate/dist/rules";

import fr from "vee-validate/dist/locale/fr";

localize({
  fr,
});

localize("fr");

extend("email", email);
extend("required", required);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);
extend("numeric", numeric);


extend("password", {
  validate: (str: string) => {
    if (str.length < 6) {
      return false;
    } else if (str.length > 200) {
      return false;
    } else if (str.search(/\d/) == -1) {
      return false;
    } else if (str.search(/[a-zA-Z]/) == -1) {
      return false;
      // eslint-disable-next-line no-useless-escape
    } else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+]/) != -1) {
      return false;
    }
    return true;
  },
  message: "Le Mot de passe doit contenir au moins 6 caractères, une lettre et un chiffre",
});

extend("phone", {
  validate: (str: string) => {
    if (str.length < 10) {
      return false;
    } else if (str.length > 15) {
      return false;
    } else if (str.search(/\d/) == -1) {
      return false;
    }
    return true;
  },
});
